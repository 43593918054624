import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "../styles.css";
import { Avatar, Carousel, Card, Typography } from "antd";
import { Flex, Box } from "reflexbox";
import Powerbi from "../static/powerbi.png"
import Tableau from "../static/tableau.png"


const PreviousWork = () => {
    
    const { Text } = Typography;







const websites = [
  {
    src:
      "https://www.chevalholding.no/",
        text: "Cheval Holding"
  },
  {
    src:
      "https://mamihomepage.web.app/",
    text: "Mami Norway"
  },
  {
    src:
      "https://louconsultingnorge.web.app/#/",
    text: "Lou Consulting"
  }
];

//Carousel add margin and padding and maybe boarder, center
const Cards = (
  <Box>
    <Carousel arrows dotPosition="top" style={{ maxWidth: "70vmax" }} autoplay>
      {websites.map(p => (
        <Card  hoverable cover={<iframe className="prevwork"  width="100%" height="100vh" title={p.text} src={p.src} />}>
          {p.text}
        </Card>
      ))}
    </Carousel>
  </Box>
);

return( 
     <div style={{marginLeft:"8%", marginBottom:"100px"}}>
    <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
      <Box flex="1 1 600px">{Cards}</Box>
       
    </Flex>
   
  </div>
);
};

export default PreviousWork;
