import React, { Suspense, useEffect, useRef, useState } from 'react'
import Front from "./Front"
import Header from "./Header"

import Contact from "./contact"
import LandingPage from './Detailed_Cards/landing-page'

import Slider from "./components/techSlider/slider"
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';


import Footer from "./footer"





export default function Home() {
  
  
  return (
    
      <div style={{display:"block"}}>
      
      <Front/>
      <LandingPage/>
      <div style={{height: "150px",overflow: "hidden"}}>
       <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: "100%", width: "100%"}}>
        <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: "none",fill: "#d25578"}}></path>
        </svg>
      </div>
      <Slider/>
      <Contact/>
      
      

     
      </div>
    
  )
}
