import React from 'react'


import PlaceCard from './place-card'
import projectStyles from '../style.module.css'
import styles from './landing-page.module.css'

const LandingPage = () => {
  return (
    <div className={styles['container']} id="Services">
   
      
      <div id="main-section" className={styles['Main']}>
        <h1>Våre tjenester</h1>
        <span className={styles['text10']}>Utvikling og Data Analytics</span>
        <div className={styles['Cards-container']}>
          <PlaceCard
            city="Konsultering"
            description="Få konsulenthjelp til dine IT prosjekter fra sertifiserte medarbeidere i Tast Consulting. Vi har erfaring med en rekke teknologier."
            image="https://cdn.lordicon.com/zpxybbhl.json"
            image_alt="IT-Consulting"
          ></PlaceCard>
          <PlaceCard
            city="Data Analytics"
            description="Få hjelp med å danne en forståelse av av din bedriftsdata. Vi spesialiserer oss i data-analyse i Python og Power Bi"
            image="https://cdn.lordicon.com/gqdnbnwt.json"
            image_alt="Data Analytics"
          ></PlaceCard>
          <PlaceCard
            city="Hjemmeside"
            description="Vi tilbyr utvikling av hjemmesider til din bedrift. Vi ønsker å gjøre det enkelt for din bedrift å opprettholde driften samt gjøre endringer"
            image="https://cdn.lordicon.com/qhgmphtg.json"
            image_alt="Hjemmeside"
          ></PlaceCard>
          <PlaceCard
            city="Web Applikasjoner"
            description="Få spesialdesignede web applikasjoner til din bedrift. Vi har erfaring med: Dashboarding, automering av prosesser og analyser"
            image="https://cdn.lordicon.com/anihkfxp.json"
            image_alt="Web Applikasjoner"
          ></PlaceCard>
          <PlaceCard
            city="Predictive Analysis"
            description="Gjennom ny teknologi tilbyr vi Artificial Intelligence til å prediktere din virksomhets fremtidige data, slik at du kan danne et bilde av fremtiden"
            image="https://cdn.lordicon.com/vlupvdhl.json"
            image_alt="Predictive Analysis"
          ></PlaceCard>
          <PlaceCard
            city="Cloud"
            description="Vi hjelper med både utvikling og integrering av Cloud Løsninger. Både i Azure og AWS"
            image="https://cdn.lordicon.com/ochimkct.json"
            image_alt="API"
          ></PlaceCard>
        </div>
      </div>
      
    </div>
  )
}

export default LandingPage
