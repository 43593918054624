const people = [
    {
      id: 1,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/480px-Python-logo-notext.svg.png",
      name: "Python",
      title: "Fleksibilitet og kompleksitet",
      quote:
        "Vi bruker Python som vår kjerneteknologi til data-analyse, maskinlæring og API utvikling"
    },
    {
      id: 2,
      image:
        "https://business.uc.edu/centers-partnerships/business-analytics/training/ms-powerbi-training/_jcr_content/main/textimage_1459434562/image.img.png/1587492218429.png",
      name: "Power BI",
      title: "Simplisitet",
      quote:
        "Vi benytter Power Bi til databehandling, visualisering og analyse. Dette gjør at du som kunde enkelt kan ta kontroll over din data "
    },
    {
      id: 3,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png",
      name: "React",
      title: "Frontend",
      quote:
        "Facebook-teknologien som benyttes til å lage komplekse web applikasjoner eller hjemmesider til din bedrift."
    },
    {
      id: 4,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Microsoft_Azure.svg/1200px-Microsoft_Azure.svg.png",
      name: "Azure",
      title: "Cloud",
      quote:
        "Vi har erfaring med Azure plattformen, hvor du enkelt kan bygge, monitorere, hoste og etablere sikkerhet rundt ditt økosystem"
    },
    {
        id: 4,
        image:
          "https://download.logo.wine/logo/Amazon_Web_Services/Amazon_Web_Services-Logo.wine.png",
        name: "AWS",
        title: "Cloud",
        quote:
          "Vi har erfaring med AWS plattformen, hvor du enkelt kan bygge,monitorere, hoste og etablere sikkerhet rundt ditt økosystem"
      }
  ];
  
  export default people;
  