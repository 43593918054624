import React from 'react'

import PropTypes from 'prop-types'

import styles from './place-card.module.css'
import 'semantic-ui-css/semantic.min.css';
import { BrowserRouter, Route, Switch, NavLink,HashRouter } from 'react-router-dom';
import Tjenester from "../Tjenester/TjenesterHome"

import Contact from "../contact"

const PlaceCard = (props) => {
  const [open,setOpen]=React.useState(false)

  function scroll(){
    document.getElementById("contact").scrollIntoView();
  }
  return (
    <div className={styles['container']}>
     
      
      <lord-icon
          src={props.image}
          trigger="hover"
          colors="primary:#121331,secondary:#d25578"
          style={{width:"100px", height:"100px"}}
        >
      </lord-icon>
      <div className={styles['container1']}>
        <span className={styles['text']}>{props.city}</span>
        <span className={styles['text1']}>{props.description}</span>
        
        </div>
        
    </div>
  )
}

PlaceCard.defaultProps = {
  image:
    'https://images.unsplash.com/photo-1514924013411-cbf25faa35bb?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1000',
  image_alt: 'image',
  city: 'City Name',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
}

PlaceCard.propTypes = {
  image: PropTypes.string,
  image_alt: PropTypes.string,
  city: PropTypes.string,
  description: PropTypes.string,
}

export default PlaceCard
