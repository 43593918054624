import React, { Component } from "react";
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
 
class Stuff extends Component {
  render() {
    return (
        <section style={{marginLeft:"-100px", marginTop:"50px", backgroundColor:"white !important"}}>
        <div class="container-fluid">
          <div >
            <div class="row" style={{display:"flex"}}>
              
              <div class="col-sm-4">
                <div class="card text-center">
                  <div class="title">
                    <i class="fa fa-plane" aria-hidden="true"></i>
                    <h2>Standard</h2>
                  </div>
                  <div class="price">
                    <h4><sup>NOK</sup>2500</h4>
                  </div>
                  <div class="option">
                    <ul>
                    <li><i class="fa fa-check" aria-hidden="true"></i>10.000kr i utvikling</li>
                      <li><i class="fa fa-check" aria-hidden="true"></i>9-17 tilgjengelighet</li>
                      <li><i class="fa fa-check" aria-hidden="true"></i>Domene for nettsted</li>
                      <li><i class="fa fa-check" aria-hidden="true"></i>100 filopplastinger</li>
                      <li style={{color:"grey"}}><i class="fa fa-check" aria-hidden="true"></i>Google SEO</li>
                    </ul>
                  </div>
                  <a ><NavLink  to="/contact">Kontakt</NavLink></a>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card text-center">
                  <div class="title">
                    <i class="fa fa-rocket" aria-hidden="true"></i>
                    <h2>Premium</h2>
                  </div>
                  <div class="price">
                    <h4><sup>NOK</sup>3125</h4>
                  </div>
                  <div class="option">
                    <ul>
                      <li><i class="fa fa-check" aria-hidden="true"></i>10.000kr i utvikling</li>
                      <li><i class="fa fa-check" aria-hidden="true"></i>Domene for nettsted og epost</li>
                      <li><i class="fa fa-check" aria-hidden="true"></i>24/7 tilgjengelighet</li>
                      <li><i class="fa fa-check" aria-hidden="true"></i>400 filopplastinger</li>
                      <li><i class="fa fa-check" aria-hidden="true"></i>Google SEO</li>
                    </ul>
                  </div>
                  <a ><NavLink  to="/contact">Kontakt</NavLink></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
 
export default Stuff;