import React, { Component } from "react";
import {
    Route,
    NavLink,
    HashRouter
  } from "react-router-dom";

  import Logo from "./static/logoTast.png"

 
class Stuff extends Component {
  render() {
    return (
        <footer class="footer-distributed">

			<div class="footer-left">
			<img src={Logo} width="300px"/>
				<p class="footer-company-name">Tast Consulting © 2021</p>
			</div>

			<div class="footer-center">
				<div>
					<i class="map marker icon"></i>
					<p><span>Hovfaret 4</span> 0275, Oslo, Norway</p>
				</div>

				<div>
					<i class="phone icon"></i>
					<p>+47 480 65 059</p>
				</div>

				<div>
					<i class="envelope icon"></i>
					<p><a href="mailto:support@company.com">support@tast.tech</a></p>
				</div>

			</div>

			<div class="footer-right">

				<p class="footer-company-about">
					<span>Org.Nr: 928 204 138</span>
					Changing the way you think about IT Consulting
				</p>

				<div class="footer-icons">

					<a ><i class="facebook icon"></i></a>
					<a ><i class="twitter icon"></i></a>
					<a ><i class="linkedin icon"></i></a>

				</div>

			</div>

		</footer>
    );
  }
}
 
export default Stuff;