import React, { Component } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import {Helmet} from "react-helmet";
import "./boxes.css"
import Hand from "../static/flame.png"
import Anim from "./Anim";

import $ from 'jquery'; 

import {
    Route,
    NavLink,
    HashRouter
  } from "react-router-dom";
  import Pricelist from "./PricelistWebPage";
  import PriceListDashboard from "./PricelistDashboard"
  import PreviousWork from "./PreviousWork";
  import { useTrail, animated } from "react-spring";



const items = ["Velkommen", "Se våre tjenester", <Icon name="handshake" />];
const config = { mass: 5, tension: 2000, friction: 200 };



 
const Home = () => {
    const [open, setOpen] = React.useState(false)
    const [toggle, set] = React.useState(true);

    const trail = useTrail(items.length, {
        config,
        opacity: toggle ? 1 : 0,
        x: toggle ? 0 : 20,
        height: toggle ? 120 : 0,
        from: { opacity: 0, x: 20, height: 0 }
      });


    $(document).on("scroll", function() {
        var pageTop = $(document).scrollTop();
        var pageBottom = pageTop + $(window).height();
        var tags = $(".tag");
      
        for (var i = 0; i < tags.length; i++) {
          var tag = tags[i];
      
          if ($(tag).position().top < pageBottom) {
            $(tag).addClass("visible");
          } else {
            $(tag).removeClass("visible");
          }
        }
      });
      
    return (
        <div style={{display:"block", }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tast Consulting | IT Tjenester | Nettside | Dashboard | Oslo </title>
                <link rel="canonical" href="tast.tech/#/tjenester" />
            </Helmet>

            <section id="zero" >
              <div className="outerHolder">
                <div className="textItem" >
                    <h1 className=" ">Få fart på din digitale vekst</h1>
                    <h3 className="text22">Vi besitter ulik kompetanse med et mål om å kunne bedre hjelpe deg og dine behov</h3>
                </div>
                <div>
                  {/** Image 1 */}
                  <div style={{height:"100%", backgroundColor:"", top:0,left:100, position:"relative",}}>
                      <img src={Hand} className="imgItem"/>
                  </div>

                </div>

              </div>

            </section>

            <section id="one" style={{height:"700px"}} >
              <Anim/>

            </section>
            
            
            <section id="first" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
              <div style={{display:"block"}}>
                <h2 style={{textAlign:"center", marginTop:"100px"}}>Våre tjenester</h2>
                <div className="outerBox" style={{ }}>

                  <div className="container">
                    <div style={{display:"block", justifyContent:"center"}}>
                      <lord-icon
                          src="https://cdn.lordicon.com/gqdnbnwt.json"
                          trigger="hover"
                          colors="primary:#121331,secondary:#d25578"
                          style={{width:"100px", height:"100px"}}
                        >
                      </lord-icon>
                      <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                        Data Analyse
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div style={{display:"block", justifyContent:"center"}}>
                      <lord-icon
                          src="https://cdn.lordicon.com/qhgmphtg.json"
                          trigger="hover"
                          colors="primary:#121331,secondary:#d25578"
                          style={{width:"100px", height:"100px"}}
                        >
                      </lord-icon>
                      <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                        Hjemmesider
                      </div>
                    </div>
                  </div>


                  <div className="container">
                    <div style={{display:"block", justifyContent:"center"}}>
                      <lord-icon
                          src="https://cdn.lordicon.com/ochimkct.json"
                          trigger="hover"
                          colors="primary:#121331,secondary:#d25578"
                          style={{width:"100px", height:"100px"}}
                        >
                      </lord-icon>
                      <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                        Cloud løsninger
                      </div>
                    </div>
                  </div>

                </div>
                <div className="outerBox">

                  <div className="container">
                    <div style={{display:"block", justifyContent:"center"}}>
                      <lord-icon
                          src="https://cdn.lordicon.com/vlupvdhl.json"
                          trigger="hover"
                          colors="primary:#121331,secondary:#d25578"
                          style={{width:"100px", height:"100px"}}
                        >
                      </lord-icon>
                      <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                        Prediktiv Analyse
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div style={{display:"block", justifyContent:"center"}}>
                      <lord-icon
                          src="https://cdn.lordicon.com/anihkfxp.json"
                          trigger="hover"
                          colors="primary:#121331,secondary:#d25578"
                          style={{width:"100px", height:"100px"}}
                        >
                      </lord-icon>
                      <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                        Web Applikasjoner
                      </div>
                    </div>
                  </div>


                  <div className="container">
                    <div style={{display:"block", justifyContent:"center"}}>
                      <lord-icon
                          src="https://cdn.lordicon.com/zpxybbhl.json"
                          trigger="hover"
                          colors="primary:#121331,secondary:#d25578"
                          style={{width:"100px", height:"100px"}}
                        >
                      </lord-icon>
                      <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                        Konsultering
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
            
    
            <section class="tag kukk">

            <div style={{backgroundColor:"", color:"black", display:"flex", marginTop:""}}>

                <div style={{marginLeft:"7%", marginTop:"5%"}}>
                <Pricelist/>                
                </div>

                <div style={{width:"40%", marginLeft:"0%", marginTop:"5%"}}>
                <h4 style={{color:"#8cb7ce"}}><i class="chart line icon"/>Hva kan vi tilby?</h4>
                <div style={{fontSize:"50px", marginBottom:"30px",}}>Nettsider</div>
                <div style={{marginRight:"50px"}}>
                
                
        
                {/* <p>
                    Velger du Standard pakken får du utviklet en nettside, koblet opp mot et ønsket domene, og vedlikehold månedtlig. 
                    Ved månedlig hjelp står vi til disposisjon med opplastning av materiale (maks 50 i måneden), endringer på siden og vedlegg av nye sider (maks 1 ny side i måneden). 
                    <br/> <div style={{fontWeight:"bold"}}>Månedtlig pris 2500 NOK </div>
                </p>

                <p>
                    Velger du Premium pakken får du utviklet en nettside, koblet opp mot et ønsket domene samt email til domenet (5 stk), månedtlig vedlikehold, og Google Search Engine optimalisering slik at du blir synlig på Google. 
                    Ved månedlig vedlikehold står vi til disposisjon med opplastning av materiale (Maks 400 i måneden), endringer på siden og vedlegg av nye sider (4 sider i måneden). 
                    <br/> <div style={{fontWeight:"bold"}}>Månedtlig pris 3125 NOK </div>
                </p> */}
                <Button onClick={()=>setOpen(true)}>Se tidligere arbeid</Button>
                </div>
                <p style={{fontSize:"10px"}}>*Alle priser er oppgitt inklusiv MVA</p>

                </div>
                
                
            </div>
            </section>

            <section class="tag blue">
            <div style={{backgroundColor:"", color:"black", display:"flex", marginBottom:""}}>
                <div style={{width:"45%", marginLeft:"10%", marginTop:"5%"}}>
                <h4 style={{color:"#8cb7ce"}}><i class="chart line icon"/>Med fokus på din data</h4>
                <div style={{fontSize:"50px", marginBottom:"30px"}}>Dashboards</div>
                {/* <p>
                Vi tilbyr utvikling av Dashboards for din virksomhet. Vi har erfaring med både
                Tableau og Power Bi, slik at alle Dashboards som blir utviklet er enkle å overvåke
                eller foreta endringer. Du vil få et skreddersydd Dashboard basert på din virksomhets data, 
                med fokus på ny innsikt. Dette gjør at de ulike aktørene i din virksomhet enkelt kan danne et overblikk
                for beslutningstakning.
                </p>
              

                <p>
                 Vi ønsker å gi deg spesialiserte og skreddersydde dashboards av din virksomhets data. 
                 Vi vil gjennom flere iterasjoner finne ut dine behov for å deretter kartlegge og utvikle et Dashboard.
                 Vi vil legge til rette for live-data slik at ditt dashboard alltid er oppdatert, og vil foreta
                 endringer etter behov, i tillegg til 1 evaluering i måneden. 
                </p> */}
        

                </div>
                <div style={{marginLeft:"10%", marginTop:"5%"}}>
                    <PriceListDashboard/>
                </div>
                
            </div>
            </section>

            <section class="tag #fff" style={{height:"600px"}}>
            <div style={{backgroundColor:"#d25578", color:"white", display:"flex",marginTop:"200px", marginBottom:"200px", width:"80%", height:"300px", borderRadius:"30px", marginLeft:"150px"}}>
                <div style={{display:"block"}}>
                    <div style={{width:"40%", marginLeft:"6%", marginTop:"40px"}}>
                    <h2 style={{fontSize:"50px", color:"white"}}>Kom i gang, og bruk din tid på å la bedriften vokse</h2>

                    </div>
                    <div style={{width:"40%", marginLeft:"80%", marginTop:"0%", display:"inline-block"}}>
                    <NavLink to="/contact"><Button ><i class="arrow right icon"/></Button></NavLink>
                    </div>                                                      
                </div>

                
                
            </div>
            </section>
            

            <Modal
            centered={true}
            size="fullscreen"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            >
            <Modal.Header>Tidligere Nettsider</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <PreviousWork/>
                </Modal.Description>
            </Modal.Content>
            
            </Modal>
           
            
      </div>
    );
  }

 
export default Home;