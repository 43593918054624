import React, { useState } from "react";
import emailjs from 'emailjs-com';
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import {Helmet} from "react-helmet";



const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const [open, setOpen] = React.useState(false)
  const [capacha, setCapacha] =React.useState(false)

  
  function sendEmail(e) {
    e.preventDefault();
   

    emailjs.sendForm('service_6lahcqm', 'template_a0mgteq', e.target, 'user_C8snmGZS8CLHGY6OU7XQE')
      .then((result) => {
          console.log(result.text);
          if(result.text=="OK"){
            document.getElementById('name').value = ''
            document.getElementById('email').value = ''
            document.getElementById('message').value = ''
            setOpen(true)
          }
      }, (error) => {
          console.log(error.text);
      });
 
}

  function onChange(value) {
    console.log("Captcha value:", value);
    setCapacha(true)
  }

  return (
      
<div id="ContactPage" class="contact-container">
 
  <div class="left-col">
  </div>
  <div class="right-col">
    
    
    <h1>Kontakt oss</h1>
    <p >
       Er din bedrift interessert i hjelp? Ta kontakt og la oss komme tilbake til deg med et ikke-bindende tilbud om hvordan vi kan hjelpe deg. 
    </p>

    
    <form id="contact-form" onSubmit={sendEmail} >
      <label for="name">Full name</label>
  <input type="text" id="name" name="user_name" placeholder="Your Full Name" required/>
      <label for="email">Email Address</label>
  <input type="email" id="email" name="user_email" placeholder="Your Email Address" required/>
      <label for="message">Message</label>
  <textarea rows="6" placeholder="Your Message" id="message" name="message" required></textarea>
      <button class="gradient-button gradient-button-1" type="submit" id="submit" name="submit">Send</button>
  
</form>

<div id="error"></div>
<div id="success-msg"></div>
  </div>
  <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
    >
      <Header icon>
        <Icon name='archive' />
        Din email er mottat
      </Header>
      <Modal.Content>
        <p>
          Vi svarer vanligvis i løpet av 1-2 dager
        </p>
      </Modal.Content>
      <Modal.Actions>
      
        <Button color='green' inverted onClick={() => {setOpen(false); }}>
          <Icon name='checkmark' /> Close
        </Button>
      </Modal.Actions>
    </Modal>
</div>

  );
};

export default ContactForm;