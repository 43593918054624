import React, { Suspense, useEffect, useRef, useState } from 'react'
import {Helmet} from "react-helmet";
import {Icon } from 'semantic-ui-react'


import Contact from "./contact"

import Home from "./Home"

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import Footer from "./footer"
import Logo from "./static/logoTast.png"
import "./styles.css";
import Tjenester from "./Tjenester/TjenesterHome"
import About from './About/About';

import 'semantic-ui-css/semantic.min.css';

import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

const firebaseConfig = {

  apiKey: "AIzaSyDtPaQNJs2BtODWkav2OwJjD47CRxzL4V8",

  authDomain: "tastconsulting.firebaseapp.com",

  projectId: "tastconsulting",

  storageBucket: "tastconsulting.appspot.com",

  messagingSenderId: "444852030366",

  appId: "1:444852030366:web:59f6bdf56711f90d6c648f",

  measurementId: "G-DZGC5TTC1K"

};


export default function App() {
  const app = initializeApp(firebaseConfig);

  const analytics = getAnalytics(app);

  
  
  
  return (
    
     <div >
       <Helmet>
                <meta charSet="utf-8" />
                <title>Tast Consulting | Norge | Oslo </title>
                <link rel="canonical" href="tast.tech/#/" />
            </Helmet>
        
       <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="*">
              <AnimationApp />
            </Route>
          </Switch>
        </Router>

        
        


      </div>

           

     
    
  )
}

function AnimationApp() {
  const [openMenu,setOpenMenu] = React.useState('130px')
  let location = useLocation();

  function openMenufunction (){
    if(openMenu=="130px"){
      setOpenMenu('')
    }else{
      setOpenMenu('130px')
    }

  }

  return (
    <div style={{position:"absolute", left:0,right:0, top:0, bottom:0}}>
      <div class="header" style={{height:openMenu}}>
        <div className='mobilemenustuff'>
          <a href="#default" class="logo"><img src={Logo} alt="Tast Consulting" width="200" height="100"></img></a>
          <a class="modifyMenu" onClick={()=>openMenufunction()}><Icon name='bars' /></a>
          </div>
          <div class="header-right">
            <a onClick={()=>openMenufunction()} ><NavLink to="/home">Hjem</NavLink></a>
            <a onClick={()=>openMenufunction()}><NavLink  to="/tjenester">Tjenester</NavLink></a>
            <a onClick={()=>openMenufunction()}><NavLink  to="/about">Om Oss</NavLink></a>
            <a onClick={()=>openMenufunction()}><NavLink  to="/contact">Kontakt</NavLink></a>
          </div>
        </div>

      <div style={{top:"40px", textAlign:"center"}}>
        <TransitionGroup>
          {/*
            This is no different than other usage of
            <CSSTransition>, just make sure to pass
            `location` to `Switch` so it can match
            the old location as it animates out.
          */}
          <CSSTransition
            key={location.pathname}
            classNames="fade"
            timeout={300}
          >
            <Switch location={location}>
            <Route path="/home" children={<Home/>}/>
            <Route path="/tjenester" children={<Tjenester/>}/>
            <Route path="/contact" children={<Contact/>}/>
            <Route path="/about" children={<About/>}/>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <div className='SVG_Bitch' style={{height: "150px",overflow: "hidden"}}>
       <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: "100%", width: "100%"}}>
        <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: "none",fill: "#d25578"}}></path>
        </svg>
        
      </div>
      <Footer/>

        
      </div>
  );
}

function NavLink(props) {
  return (
    <li >
      <Link  {...props} style={{ color: "inherit" }} />
    </li>
  );
}