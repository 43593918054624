import React, { Component } from "react";
import {
    Route,
    NavLink,
    HashRouter
  } from "react-router-dom";
 
class Stuff extends Component {
  render() {
    return (
        <section style={{marginLeft:"-100px", marginTop:"50px", backgroundColor:"white !important"}}>
        <div class="container-fluid">
          <div >
            <div class="row" style={{display:"flex"}}>
              <div class="col-sm-4" >
                <div class="card text-center">
                  <div class="title">
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                    <h2>Utvikling</h2>
                  </div>
                  <div class="price">
                    <h4><sup>Kontakt</sup>Oss </h4>
                  </div>
                  <div class="option">
                    <ul>
                      <li><i class="fa fa-check" aria-hidden="true" ></i>PowerBi/Tableau</li>
                      <li ><i class="fa fa-check" aria-hidden="true"></i>24/7 tilgjengelighet</li>
                      <li><i class="fa fa-check" aria-hidden="true" ></i>Live Data</li>
                      <li ><i class="fa fa-check" aria-hidden="true"></i>Support</li>
                    </ul>
                  </div>
                  <a ><NavLink  to="/contact">Kontakt</NavLink></a>
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </section>
    );
  }
}
 
export default Stuff;