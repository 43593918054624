import React from 'react'
import Peder from "../static/peder.jpg"
import Henrik from "../static/henrik.jpg"
import "./about.css"


export default function About() {
  
  
  return (
    <div class="flow">
  <h1 className='text1'>Vårt team</h1>
  <div class="team">
    <ul class="auto-grid" role="list">
      <li className='profilecard'>
        <a  class="profile">
          <h2 class="profile__name">Peder Tanberg</h2>
          <p>Developer/Founder</p>
          <img alt="Peder Tanberg" src={Peder} />
        </a>
      </li>
      <li className='profilecard'>
        <a  class="profile">
          <h2 class="profile__name">Henrik Stangeland</h2>
          <p>Developer/Founder</p>
          <img alt="Profile shot for Celina Harris" src={Henrik} />
        </a>
      </li>
      
    </ul>
  </div>
</div>

    
  )
}
